import ApplicationController from './application_controller';

class ComplianceController extends ApplicationController {

  initialize() {

    super.initialize();

    this.markAsRead = this.markAsRead.bind(this);
    this.submit = this.submit.bind(this);
  }

  connect() {

    super.connect();

    this.addEventListeners();
    this.positionPopover();
  }

  disconnect() {

    super.disconnect();
    this.removeEventListeners();
  }

  popoverMouseOver() {

    if (!this.valid) {

      this.positionPopover();
      this.popoverTarget.classList.remove('hidden');
    }
  }

  popoverMouseOut() {

    this.popoverTarget.classList.add('hidden');
  }

  markAsRead() {

    if (Math.abs(document.body.scrollHeight - (window.innerHeight + window.scrollY)) <= 100) {

      document.removeEventListener('scroll', this.markAsRead);
      this.stimulate('ComplianceReflex#mark_as_read');
    }
  }

  submit(event) {

    event.preventDefault();
    if (!this.valid) return;

    event.currentTarget.submit();
  }

  addEventListeners() {

    document.addEventListener('stimulus-reflex:connected', this.markAsRead);
    document.addEventListener('scroll', this.markAsRead);
    this.element.addEventListener('submit', this.submit);
  }

  removeEventListeners() {

    document.removeEventListener('stimulus-reflex:connected', this.markAsRead);
    document.removeEventListener('scroll', this.markAsRead);
    this.element.removeEventListener('submit', this.submit);
  }

  positionPopover() {

    this.popoverTarget.setAttribute(
      'style',
      `transform:translate(${this.data.get('popoverTranslateX')}, ${this.data.get('popoverTranslateY')});`,
    );
  }

  get valid() {

    return this.popoverConditional && this.checkboxTarget.checked;
  }

  get popoverTranslateX() {

    return this.data.get('popoverTranslateX');
  }

  get popoverTranslateY() {

    return this.data.get('popoverTranslateY');
  }

  get popoverConditional() {

    return this.data.get('popoverConditional') === 'true';
  }
}

ComplianceController.targets = [
  'checkbox',
  'popover',
];

export default ComplianceController;
