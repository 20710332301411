// Adapted from https://github.com/hopsoft/debounced
//  extended to allow for multiple timeouts with their own prefixes
//  limited to not take any configuration options as we can make changes in place.

const timeouts = [
  // 200,
  350,
  1000,
];

// Uncomment to include event in list that are debounced.
const events = [
  // 'DOMContentLoaded',
  // 'abort',
  // 'animationcancel',
  // 'animationend',
  // 'animationiteration',
  // 'animationstart',
  // 'auxclick',
  'change',
  'click',
  // 'compositionend',
  // 'compositionstart',
  // 'compositionupdate',
  // 'contextmenu',
  // 'copy',
  // 'cut',
  // 'dblclick',
  // 'drag',
  // 'dragend',
  // 'dragenter',
  // 'dragleave',
  // 'dragover',
  // 'dragstart',
  // 'drop',
  // 'error',
  // 'focusin',
  // 'focusout',
  // 'fullscreenchange',
  // 'fullscreenerror',
  // 'hashchange',
  'input',
  // 'keydown',
  // 'keyup',
  // 'mousedown',
  // 'mousemove',
  // 'mouseout',
  // 'mouseover',
  // 'mouseup',
  // 'paste',
  // 'pointercancel',
  // 'pointerdown',
  // 'pointerlockchange',
  // 'pointerlockerror',
  // 'pointermove',
  // 'pointerout',
  // 'pointerover',
  // 'pointerup',
  // 'popstate',
  // 'reset',
  'scroll',
  // 'select',
  'submit',
  // 'touchcancel',
  // 'touchend',
  // 'touchmove',
  // 'touchstart',
  // 'transitioncancel',
  // 'transitionend',
  // 'transitionrun',
  // 'transitionstart',
  // 'visibilitychange',
  // 'wheel',
];

const initializedEvents = [];

export const debounce = (fn, timeout) => {

  let timeoutId;

  return (...inputEvent) => {

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {

      timeoutId = null;
      fn(...inputEvent);
    }, timeout);
  };
};

const dispatch = prefix => event => {

  const { bubbles, cancelable, composed } = event;

  const debouncedEvent = new CustomEvent(`${prefix}:${event.type}`, {
    bubbles,
    cancelable,
    composed,
    detail: { originalEvent: event },
  });

  setTimeout(event.target.dispatchEvent(debouncedEvent));
};

export const initializeEvent = (name, timeout) => {

  const prefix = `debounced-${timeout}`;
  const eventKey = `${prefix}:${name}`;
  if (initializedEvents.includes(eventKey)) return;

  initializedEvents.push(eventKey);

  const debouncedDispatch = debounce(dispatch(prefix), timeout);
  document.addEventListener(name, event => debouncedDispatch(event));
};

const initialize = () => {

  timeouts.forEach(timeout => events.forEach(event => initializeEvent(event, timeout)));
};

export default { initialize };
