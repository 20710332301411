import { Popover } from 'tailwindcss-stimulus-components';

class PopoverController extends Popover {
  initialize() {

    if (!!this.translateX && !!this.translateY) {

      this.contentTarget.setAttribute(
        'style',
        `transform:translate(${this.translateX}, ${this.translateY});`,
      );
    }
  }

  get translateX() {

    return this.data.get('translateX');
  }

  get translateY() {

    return this.data.get('translateY');
  }
}

export default PopoverController;
